import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";
import BaseLayout from "../global/BaseLayout";
import TransparentHeader from "../global/TransparentHeader";
import ServicesBlocks from "../Services/ServicesBlock";
import CountertUp from "../global/CounterUp";
import ContactInfo from "../Contact/ContactInfo";

function ServicesDetail() {
  const { rpdata } = useContext(GlobalDataContext);
  const { id } = useParams();
  let previousClass = "row";
  let previousMarginClass = 'md:mr-[-40px]';

  return (
    <BaseLayout PageName="Services Detail">
      {rpdata?.dbServices?.map((item, index) => {
        if (item.name.replace(" ", "-").toLowerCase() === id) {
          return (
            <div key={index}>
              <TransparentHeader
                bgimg={rpdata?.stock?.[1]}
                headertitle={item.name}
                Subheader={item.name}
              />
              <div className="md:w-4/5 w-[95%] mx-auto">
                {item.description.map((service, index) => {
                  let classes = index % 2 === 0 ? "row" : "row-reverse";
                  let newClass = previousClass === "row" ? "row-reverse" : "row";
                  classes = newClass;
                  previousClass = classes;
                  const titleDisplay = index === 0 ? 'block' : 'hidden';

                  let marginClass = index % 2 === 0 ? 'md:mr-[-40px]' : 'md:ml-[-40px]';
                  let newMarginClass = previousMarginClass === "md:mr-[-40px]" ? "md:ml-[-40px]" : "md:mr-[-40px]";
                  marginClass = newMarginClass;
                  previousMarginClass = marginClass;

                  return (
                    <ServicesBlocks
                      key={index}
                      ServiceName={item.name}
                      TextService={service.text}
                      itemServices={service.lists.map((list, index) => {
                        return (
                          list.length > 0 ? <li key={index}><i className="fa fa-arrow-right mr-2"></i>{list}</li> : null
                        )
                      })}
                      bgimg={service.img}
                      ClassesRow={classes}
                      DisplayNone={titleDisplay}
                      classesMargin={marginClass}
                    />
                  );
                })}
              </div>
              <CountertUp />
              <ContactInfo />
            </div>
          );
        }
        return null;
      })}
    </BaseLayout>
  );
}

export default ServicesDetail;
